import React, { useState, useEffect } from 'react';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import '../styles/OperatorPage.css';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { toast } from 'react-toastify';
import Notification from '../components/notification';
import Loading from "../components/loader"; 

function OperatorPage() {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const [appointments, setAppointments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState('upcoming');
  const [totalBookings, setTotalBookings] = useState(0);

  useEffect(() => {
    fetchBookings();
  }, [filter]); // Re-fetch when filter changes

  const fetchBookings = async () => {
    setIsLoading(true);
    try {
      const response = await fetch('https://api-lyssa.com/api/bookings/filter', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ filter }),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch bookings');
      }
      const data = await response.json();
      setAppointments(data);
      setTotalBookings(data.length);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching bookings:', error);
      toast.error('Error fetching bookings');
      setIsLoading(false);
    }
  };

  const handleAccept = async (id) => {
    try {
      const response = await fetch(`https://api-lyssa.com/api/bookings/accept/${id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        throw new Error('Failed to update booking');
      }
  
      const updatedBooking = await response.json();
      setAppointments(appointments.map(app => app._id === id ? updatedBooking : app));
      toast.success('Booking modified successfully');
    } catch (error) {
      console.error('Error updating booking:', error);
      toast.error('Error updating booking');
    }
  };
  
  const handleCancel = async (id) => {
    try {
      const response = await fetch(`https://api-lyssa.com/api/bookings/cancel/${id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        throw new Error('Failed to update booking');
      }
  
      const updatedBooking = await response.json();
      setAppointments(appointments.map(app => app._id === id ? updatedBooking : app));
      toast.success('Booking cancelled successfully');
    } catch (error) {
      console.error('Error updating booking:', error);
      toast.error('Error modified booking');
    } 
  };

  const formatDate = (dateValue) => {
    if (typeof dateValue === 'string') {
      return new Date(dateValue).toLocaleString();
    } else if (typeof dateValue === 'object' && dateValue.$date) {
      return new Date(dateValue.$date).toLocaleString();
    } else if (typeof dateValue === 'number') {
      return new Date(dateValue).toLocaleString();
    }
    return 'Invalid Date';
  };

  const handleModify = (appointment) => {
    if (appointment.OperatorConfirmation === 'CANCELLED') {
      toast.error("You can't modify a cancelled booking.");
      return;
    }
    navigate('/details', { state: { appointment } });
  };

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  if (isLoading) {
    return <Loading/>;
  }

  return (
    <div className="operator-page">
      <Notification/>
      <div className="page-header">
        <h1>Dashboard</h1>
        <button className="logout-btn" onClick={handleLogout}>
          <LogoutIcon /> Logout
        </button>
      </div>

      <div className="filter-buttons">
        {['upcoming',  'PENDING', 'ACCEPTED', 'CANCELLED','past', 'all bookings'].map((buttonFilter) => (
          <button
            key={buttonFilter}
            className={`filter-btn ${filter === buttonFilter ? 'active' : ''}`}
            onClick={() => handleFilterChange(buttonFilter)}
          >
            {buttonFilter.charAt(0).toUpperCase() + buttonFilter.slice(1)}
          </button>
        ))}
      </div>

      <div className="total-bookings">
        <strong>TOTAL:</strong> {totalBookings}
      </div>

      <div className="appointment-cards">
        {appointments.map((appointment) => (
          <div className="appointment-card" key={appointment._id}>
            <div className="card-header">
              <h2>{appointment.name}</h2>
              <button 
                className={`modify-btn ${appointment.OperatorConfirmation === 'CANCELLED' ? 'disabled' : ''}`}
                onClick={() => handleModify(appointment)}
                disabled={appointment.OperatorConfirmation === 'CANCELLED'}
              >
                <EditIcon /> MODIFY
              </button>
            </div>
            <div className="card-content">
              <p><strong>Email:</strong> {appointment.email}</p>
              <p><strong>Phone:</strong> {appointment.countryCode} {appointment.phoneNumber}</p>
              <p><strong>Date & Time:</strong> {formatDate(appointment.dateTime)}</p>
              <p><strong>Service:</strong> {appointment.serviceCategory} - {appointment.serviceType}</p>
              <p><strong>Description:</strong> {appointment.description}</p>
              <p><strong>Status:</strong> <span className={`status ${appointment.OperatorConfirmation.toLowerCase()}`}>{appointment.OperatorConfirmation}</span></p>
            </div>
            <div className="card-actions">
              <button 
                className={`accept-btn ${appointment.OperatorConfirmation === 'ACCEPTED' ? 'active' : ''}`}
                onClick={() => handleAccept(appointment._id)}
              >
                <DoneIcon /> {appointment.OperatorConfirmation === 'ACCEPTED' ? 'ACCEPTED' : 'ACCEPT'}
              </button>
              <button 
                className={`cancel-btn ${appointment.OperatorConfirmation === 'CANCELLED' ? 'active' : ''}`}
                onClick={() => handleCancel(appointment._id)}
              >
                <CloseIcon /> {appointment.OperatorConfirmation === 'CANCELLED' ? 'CANCELLED' : 'CANCEL'}
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default OperatorPage;
