// src/Pages/LoginPage.jsx
import React, { useState, useEffect  } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import '../styles/LoginPage.css';
import { toast } from 'react-toastify';
import Notification from '../components/notification';
import { useAuth } from '../hooks/useAuth';

// import { Link } from 'react-router-dom';
function LoginPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const { isLoggedIn, login } = useAuth();
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/');
    }
  }, [isLoggedIn, navigate]);


  const handleSubmit = async (e) => {
    e.preventDefault();
    e.preventDefault();
    try {
      const response = await fetch("https://api-lyssa.com/api/auth/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      if (response.ok) {
        const data = await response.json();
        login(data.token, data.user);
        toast.success('Login successful!');
        navigate('/operator');
      } 
      else {
        const errorData = await response.json();
        toast.error(errorData.message || 'Login failed. Please try again.');
      }
    } catch (error) {
      console.error('Login error:', error);
      toast.error('An error occurred. Please try again later.');
    }
  };

  return (
    <div className="login-page">
        <Notification/>
        <div className="login-content">
            <Link to="/" className="return-home">
                &larr; Home
            </Link>
        <div className="login-container">
            <h2>Operator Login</h2>
            <form className="login_content_form" onSubmit={handleSubmit}>
            <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
            />
            <div className="password-input">
                <input
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
                <button
                    type="button" // Prevent form submission
                    className="password-eye"
                    onClick={togglePasswordVisibility}
                >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                </button>
                </div>
            <button type="submit">LOG IN</button>
            </form>
        </div>
        </div>
    </div>
  );
}

export default LoginPage;
