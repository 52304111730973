// src/App.jsx
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './Pages/HomePage';
import BookingPage from './Pages/Booking';
import LoginPage from './Pages/LoginPage';
import OperatorPage from './Pages/OperatorPage';
import { colorSchemes } from './styles/colors';
import Details from './Pages/Details';
import './styles/App.css';

function App() {
  const [colorScheme, setColorScheme] = useState('caffeeKate');

  useEffect(() => {
    const root = document.documentElement;
    const colors = colorSchemes[colorScheme];
    
    root.style.setProperty('--color-primary', colors.primary);
    root.style.setProperty('--color-secondary', colors.secondary);
    root.style.setProperty('--color-accent', colors.accent);
    root.style.setProperty('--color-text', colors.text);
    root.style.setProperty('--color-background', colors.background);
  }, [colorScheme]);

  const changeColorScheme = (scheme) => {
    setColorScheme(scheme);
  };

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/book" element={<BookingPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/operator" element={<OperatorPage />} />
          <Route path="/details" element={<Details />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;