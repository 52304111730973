// src/styles/colors.js
export const colorSchemes = {
    dustyRose: {
      primary: '#DCAE96',
      secondary: '#C69C8A',
      accent: '#E8C3B0',
      text: '#4A3E3A',
      background: '#F5E6E0',
    },
    caffeeKate: {
      primary: '#A67B5B',
      secondary: '#8C6548',
      accent: '#C09A7E',
      text: '#3A2E28',
      background: '#F0E6DC',
    },
    espressoShot: {
      primary: '#4B3832',
      secondary: '#645452',
      accent: '#7E6E6A',
      text: '#FFF9F5',
      background: '#D7CEC7',
    },
    pinkNude: {
      primary: '#DDC0B4',
      secondary: '#C7A99D',
      accent: '#F1D6CB',
      text: '#4A3E3A',
      background: '#FFF5F0',
    },
  };