// src/pages/HomePage.jsx
import React, { useState, useEffect, useRef } from 'react';
// import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Notification from '../components/notification';
import '../styles/HomePage.css';
import makeupImg from '../Photos/makeup.JPG';
import braidingImg from '../Photos/braids.jpg';
import flowersImg from '../Photos/flowers.JPG';
import giftImg from '../Photos/IMG_9368.jpg';
import { useAuth } from '../hooks/useAuth';
import { useNavigate, Link } from 'react-router-dom';

const services = {
    Makeup: {
      items: ['Bridal', 'Special Event', 'Photoshoot', 'Lessons'],
      price: '5k - 35k Rwf per person'
    },
    Braiding: {
      items: ['Box Braids', 'Knotless Braids', 'Cornrows'],
      price: '25k+ Rwf per person'
    },
    Flowers: {
      items: ['Special Event', "Saint Valentine's", 'Custom Flowers'],
      price: '$20k+ per bouquet'
    },
    "Gift Wrapping": {
      items: ['Weddings', 'Birthdays', 'Corporate', 'Custom'],
      price: '3k+ Rwf per gift'
    }
  };

function HomePage() {
    const navigate = useNavigate();
    const { isLoggedIn } = useAuth();
    const [showNotification, setShowNotification] = useState(false);
    const notificationShown = useRef(false);
    const [emailFormData, setEmailFormData] = useState({
        contactEmail: '',
        subject: '',
        message: ''
    });

    const handleEmailFormChange = (e) => {
        const { name, value } = e.target;
        setEmailFormData(prevData => ({
          ...prevData,
          [name]: value
        }));
      };
      
      const handleEmailFormSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch('https://api-lyssa.com/api/bookings/contact-us', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(emailFormData),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            console.log(data);

            // Clear the form after submission
            setEmailFormData({
                contactEmail: '',
                subject: '',
                message: ''
            });

            // Show a success message
            toast.success('Message sent successfully!');
        } catch (error) {
            console.error('Error:', error);
            toast.error('Failed to send message. Please try again.');
        }
    };


    const handleOperatorClick = (e) => {
        e.preventDefault();
        if (isLoggedIn) {
          navigate('/operator');
        } else {
          navigate('/login');
        }
      };

      useEffect(() => {
        
        const bookingSuccess = localStorage.getItem('bookingSuccess');
       
        
        if (bookingSuccess === 'true' && !notificationShown.current) {
          setShowNotification(true);
          notificationShown.current = true;
          localStorage.removeItem('bookingSuccess');
        }
      }, []);
    
      useEffect(() => {
        if (showNotification) {
          toast.success("Booking sent successfully. Check email for comfirmation", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          setShowNotification(false);
        }
      }, [showNotification]);

  return (
    <>
    <Notification />
    <div className="home-page">
      <header>
        <h1>Lysa Aesthetics</h1>
        <p>Beauty & Style Services</p>
        <Link to="/login" className="operator-btn" onClick={handleOperatorClick}>Admin</Link>
      </header>
      <main>
        <section className="hero">
          <h2>Elevate Your Style</h2>
          <p>Makeup • Braiding • Flowers</p>
          <Link to="/book" className="book-now-btn">Book Now</Link>
        </section>
        <section className="services">
          <h2>Our Services</h2>
          <div className="service-grid">
          {Object.entries(services).map(([category, { items, price }]) => (
            <div 
                key={category} 
                className="service-card"
            >
                <div className="service-content">
                <h3>{category}</h3>
                <ul>
                    {items.map(item => <li key={item}>{item}</li>)}
                </ul>
                <p className="service-price">{price}</p>
                <p className="price-disclaimer">*Prices are negotiable, please contact us for specific pricing</p>
                </div>
                <div className="service-image">
                {category === 'Makeup' && <img src={makeupImg} alt="Makeup" />}
                {category === 'Braiding' && <img src={braidingImg} alt="Braiding" />}
                {category === 'Flowers' && <img src={flowersImg} alt="Flowers" />}
                {category === 'Gift Wrapping' && <img src={giftImg} alt="Gift Wrapping" />}
                </div>
            </div>
            ))}
          </div>
        </section>
        <section className="testimonials">
          <h2>Client Love</h2>
          <div className="testimonial-carousel">
            <div className="testimonial">
              <p>"Absolutely loved my makeup and braids! The artists were professional and friendly."</p>
              <span>- Sarah M.</span>
            </div>
            <div className="testimonial">
              <p>"The flower arrangements for my event were stunning. Highly recommend!"</p>
              <span>- Emily R.</span>
            </div>
          </div>
        </section>
      </main>
      <section className="contact-info">
        <p>If you have any questions, contact us: <a href="tel:+250729327628" style={{color: 'blue'}}>+250 729 327 628</a> or email us at <a href="mailto:aestheticlysa@gmail.com" style={{color: 'blue'}}>aestheticlysa@gmail.com</a></p>
    </section>

    <div className="separator">
        <hr />
        <span className="or-text">OR</span>
        <hr />
    </div>

    <section className="email-form">
        <form onSubmit={handleEmailFormSubmit}>
            <div className="form-group">
            <input
                type="email"
                id="contactEmail"
                name="contactEmail"
                value={emailFormData.contactEmail}
                onChange={handleEmailFormChange}
                placeholder="example@email.com"
                required
            />
            </div>
            <div className="form-group">
            <input
                type="text"
                id="subject"
                name="subject"
                value={emailFormData.subject}
                onChange={handleEmailFormChange}
                placeholder="Subject"
                required
            />
            </div>
            <div className="form-group">
            <textarea
                id="message"
                name="message"
                value={emailFormData.message}
                onChange={handleEmailFormChange}
                placeholder="Body/Description"
                rows="4"
                maxLength="500"
                required
            />
            </div>
            <button type="submit" className="submit-btn">Send Message</button>
        </form>
        </section>
      <footer>
        <p>&copy; 2024 Lysa Aesthetics. All rights reserved.</p>
      </footer>
      <div className="sticky-book-btn">
        <Link to="/book" className="book-now-btn">Book Now</Link>
      </div>
    </div>
    </>
    
  );
}

export default HomePage;
